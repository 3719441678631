*{
    margin:0;
    padding:0;
}
body{
    margin-top:10vh;
}
#mic{
    font-size:10vh;
    cursor: pointer;
    width:10vh;
    margin:0 auto;
    text-align:center;
}
#mic:hover{
    opacity:0.5;
}

#command, #intent{
    width:90vw;
    margin:0 auto;
    text-align:center;
}